import React from 'react';

const SiteFooter = () => (
  <footer className="footer">
    <div className="content has-text-centered is-size-7">
      Copyright © 2021 Clubix Oy
    </div>
  </footer>
);

export default SiteFooter;
