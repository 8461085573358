import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Navbar from '../components/Navbar';
import SiteFooter from '../components/SiteFooter';
import './all.sass';
import bg from '../../static/img/clubx-bg-darker.png';

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        left: file(relativePath: { eq: "clubx-rbd1.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 253
              tracedSVGOptions: { color: "#433f3b", blackOnWhite: false }
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
        right: file(relativePath: { eq: "clubx-rbd2.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 253
              tracedSVGOptions: { color: "#433f3b", blackOnWhite: false }
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
      }
    `}
  >
    {({ left, right }) => (
      <div className="container">
        <Helmet title="Club X" />
        <div className="columns is-gapless">
          <div className="column is-one-fifth is-hidden-touch">
            <figure className="image" style={{ margin: '0.5rem' }}>
              <GatsbyImage
                image={left.childImageSharp.gatsbyImageData}
                alt=""
              />
            </figure>
          </div>
          <div
            className="column"
            style={{
              background: `url(${bg})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#231f20',
              backgroundBlendMode: 'lighten',
            }}
          >
            <Navbar />
            {children}
          </div>
          <div className="column is-one-fifth is-hidden-touch">
            <figure className="image" style={{ margin: '0.5rem' }}>
              <GatsbyImage
                image={right.childImageSharp.gatsbyImageData}
                alt=""
              />
            </figure>
          </div>
        </div>
        <SiteFooter />
      </div>
    )}
  </StaticQuery>
);

export default TemplateWrapper;
