import React from 'react';
import { Link } from 'gatsby';

const NavbarLink = (props) => (
  <Link
    className="navbar-item is-uppercase has-text-weight-semibold"
    activeClassName="is-active"
    {...props}
  >
    {props.children}
  </Link>
);

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  toggleOpen = () =>
    this.setState((state) => ({ ...state, open: !state.open }));

  render() {
    return (
      <nav className="navbar is-transparent">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <figure className="image clubx-logo" />
          </Link>
          <a // eslint-disable-line jsx-a11y/anchor-is-valid
            role="button"
            className={
              this.state.open ? 'navbar-burger is-active' : 'navbar-burger'
            }
            aria-label="menu"
            aria-expanded="false"
            onClick={this.toggleOpen}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          className={this.state.open ? 'navbar-menu is-active' : 'navbar-menu'}
        >
          <div className="navbar-start">
            <NavbarLink to="/uutiset/">Uutiset</NavbarLink>
            <NavbarLink to="/tapahtumat/">Tapahtumat</NavbarLink>
            <NavbarLink to="/kuvia/">Kuvia</NavbarLink>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link
                className="navbar-link is-uppercase has-text-weight-semibold"
                activeClassName="is-active"
                to="/esittely/"
              >
                Info
              </Link>
              <div className="navbar-dropdown is-boxed">
                <NavbarLink to="/esittely/">Esittely</NavbarLink>
                <NavbarLink to="/saannot/">Säännöt</NavbarLink>
                <NavbarLink to="/hinnasto/">Hinnasto</NavbarLink>
                <NavbarLink to="/rekisteriseloste/">
                  Rekisteriseloste
                </NavbarLink>
                <NavbarLink to="/korona/">Korona-linjaus</NavbarLink>
                <NavbarLink to="/yhteystiedot/">Yhteystiedot</NavbarLink>
                <hr className="navbar-divider" />
                <NavbarLink to="/in-english/">In English</NavbarLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
